/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import { groupBy } from "lodash";
import {
  Boolean,
  Null,
  Number,
  Partial,
  Array as RArray,
  Record as RRecord,
  Static,
  String,
  Tuple,
  Union,
} from "runtypes";

import { VariableName } from "../typeBrands.js";

export function mergeParamReferences(
  ...refs: Array<readonly ParamReference[]>
): ParamReference[] {
  const groupedByParam = groupBy(refs.flat(), (ref) => ref.param);
  return Object.values(groupedByParam).map((group) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { param } = group[0]!;
    return {
      param,
      locations: group.flatMap(({ locations }) => locations),
    };
  });
}

export const SourcePosition = RRecord({
  line: Number,
  column: Number,
});
export type SourcePosition = Static<typeof SourcePosition>;

export const SourceRange = Tuple(SourcePosition, SourcePosition);
export type SourceRange = Static<typeof SourceRange>;

export const SourceLocation = SourcePosition.And(
  Partial({
    length: Union(Number, Null),
  }),
);
export type SourceLocation = Static<typeof SourceLocation>;

export const ParamReferenceKey = Union(String, Number);
export type ParamReferenceKey = Static<typeof ParamReferenceKey>;

export const ParamReference = RRecord({
  param: VariableName,
  locations: RArray(SourceLocation).asReadonly(),
  referencedKeys: RArray(ParamReferenceKey).asReadonly().nullable().optional(),
});
export type ParamReference = Static<typeof ParamReference>;

export const CellReferencesV1 = RRecord({
  referencedParams: RArray(VariableName).asReadonly(),
  newParams: RArray(VariableName).asReadonly(),
});

export type CellReferencesV1 = Static<typeof CellReferencesV1>;

export const FunctionDefinition = RRecord({
  name: String,
  src: String,
});
export type FunctionDefinition = Static<typeof FunctionDefinition>;

export const PythonCellReferencesV1 = CellReferencesV1.extend({
  imports: RArray(VariableName).asReadonly(),
  importStrings: RArray(String).asReadonly(),
  functions: RArray(FunctionDefinition).asReadonly(),
});
export type PythonCellReferencesV1 = Static<typeof PythonCellReferencesV1>;

export const SqlCellReferencesV1 = CellReferencesV1.extend({
  containsBlocks: Boolean,
});
export type SqlCellReferencesV1 = Static<typeof SqlCellReferencesV1>;

export const CellReferencesParseError = RRecord({
  error: String,
});

export type CellReferencesParseError = Static<typeof CellReferencesParseError>;

export const ParsedPythonCellReferencesV1 = Union(
  PythonCellReferencesV1,
  CellReferencesParseError,
);
export type ParsedPythonCellReferencesV1 = Static<
  typeof ParsedPythonCellReferencesV1
>;

export const FormatSourceError = RRecord({
  error: String,
});
export type FormatSourceError = Static<typeof FormatSourceError>;

export const FormatSourceResponse = Union(String, FormatSourceError);
export type FormatSourceResponse = Static<typeof FormatSourceResponse>;

export const HqlToSqlErrorResponse = RRecord({
  message: String,
  is_user_facing: Boolean,
});

export type HqlToSqlErrorResponse = Static<typeof HqlToSqlErrorResponse>;
