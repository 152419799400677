import { keyBy, merge } from "lodash";

import { assertNever } from "../errors.js";
import { CHART_FOLD_KEYS } from "../explore/exploreConstants.js";
import { notEmpty } from "../notEmpty";
import { Requiredish } from "../utils";

import {
  BaseChartSeries,
  ChartAxis,
  ChartAxisWithData,
  ChartCustomSort,
  ChartLayer,
  ChartSeries,
  ChartSeriesGroup,
  ChartSort,
  ChartTypeSelectorOption,
  LayeredChartSpec,
  VegaSelectionConfigs,
} from "./types";

export function isTidyMultiSeries(series: ChartSeries): boolean {
  return (
    series.dataFrameColumns.length === 1 && series.colorDataFrameColumn != null
  );
}

export function stripToBaseSeries(
  series: ChartSeries,
): Requiredish<BaseChartSeries> {
  // type-safe way to strip out the non-base properties
  return {
    id: series.id,
    name: series.name,
    type: series.type,
    axis: series.axis,
    dataFrame: series.dataFrame,
    dataFrameColumns: series.dataFrameColumns,
    colorDataFrameColumn: series.colorDataFrameColumn,
    colorOrder: series.colorOrder,
    color: series.color,
    opacity: series.opacity,
    tooltip: series.tooltip,
    dataLabels: series.dataLabels,
    totalDataLabels: series.totalDataLabels,
    legendTitle: series.legendTitle,
  };
}

export function clearSeriesDataFrameColumns(series: ChartSeries): ChartSeries {
  const { colorDataFrameColumn, ...restSeries } = series;
  return {
    ...clearSeriesValues(restSeries),
    dataFrameColumns: [],
  };
}

export function clearSeriesValues(series: ChartSeries): ChartSeries {
  return {
    ...series,
    opacity:
      series.opacity.type === "series"
        ? {
            type: "static",
            value: 1,
          }
        : series.opacity,
    colorOrder: "ascending",
    color:
      series.color.type === "series"
        ? {
            type: "static",
            color: series.color.defaultColor,
          }
        : series.color.type === "dataframe"
          ? {
              type: "static",
            }
          : series.color,
    tooltip:
      series.tooltip?.type === "manual"
        ? {
            type: "auto",
          }
        : series.tooltip,
  };
}

export function getOrderedSeriesValues(
  order: ChartCustomSort | ChartSort,
  seriesValues: readonly string[] | undefined,
  // horizontal stacked bar chart color order is opposite of the stack order
  // for ChartCustomSort and ascending/descending ChartSort
  // pass true here to fix the order here when it impacts:
  // - display of the color order in the chart editor controls
  // - display of the color order in the legend
  // and we default to false for all other scenarios
  // to avoid impacting existing chart specs and existing bar rendering order/color
  isForHorizontalStackedBarLegendOrSortControls = false,
): string[] {
  if (seriesValues == null || seriesValues.length === 0) {
    return ChartCustomSort.guard(order)
      ? isForHorizontalStackedBarLegendOrSortControls
        ? [...order].reverse()
        : order
      : [];
  }

  const resolvedSeriesValues = seriesValues.slice().sort();

  if (order.length === 0) {
    return resolvedSeriesValues;
  }

  if (ChartCustomSort.guard(order)) {
    const validValueSet = new Set(resolvedSeriesValues);
    const orderedSeriesValues: string[] = [];
    order.forEach((value) => {
      if (validValueSet.has(value)) {
        orderedSeriesValues.push(value);
        validValueSet.delete(value);
      }
    });
    if (isForHorizontalStackedBarLegendOrSortControls) {
      orderedSeriesValues.reverse();
    }
    // add on remaining items
    return orderedSeriesValues.concat(...validValueSet);
  }

  // Check if all strings are either numbers or "null"
  const allNumbersOrNull = seriesValues.every(
    (str) => str === "null" || !isNaN(+str),
  );

  const sortedSeriesValues = seriesValues.slice();
  if (allNumbersOrNull) {
    // Sort numerically with "null" last
    sortedSeriesValues.sort((a, b) => {
      if (a === "null") return 1;
      if (b === "null") return -1;
      return +a - +b;
    });
  } else {
    // Sort strings lexicographically
    sortedSeriesValues.sort();
  }

  if (
    isForHorizontalStackedBarLegendOrSortControls &&
    (order === "ascending" || order === "descending")
  ) {
    sortedSeriesValues.reverse();
  }
  switch (order) {
    case "descending":
      return sortedSeriesValues.reverse();
    default:
      return sortedSeriesValues;
  }
}

export function getResolvedSeriesGroups(layer: ChartLayer): ChartSeriesGroup[] {
  if (layer.seriesGroups == null || layer.seriesGroups.length === 0) {
    return [layer.series.map((s) => s.id)];
  }
  return layer.seriesGroups;
}

export function mergeVegaSelectionConfigs(
  configs: VegaSelectionConfigs[],
): VegaSelectionConfigs {
  const result: VegaSelectionConfigs = {};
  merge(result, ...configs);
  return result;
}

// Grouped bar charts DO NOT WORK when the x-axis is "continuous" --
// if the x-axis type is numeric or temporal. In this case, we resolve
// the x-axis to be of string type if it's been set as something different.
// This would affect the layout of other series as well, to a degree,
// as a line chart with continuous axis is a bit different from one with
// categorical axis. However, having different axis data types between
// series results in multiple x-axes on the chart which looks broken and
// given that the grouped bar chart doesn't work at all (which is worse than
// slight changes in visuals of other series types) unless the x-axis
// data type is resolved, we resolve the x-axis for all series even if
// there are non-grouped bar series types.
export function resolveXAxis(
  xAxis: ChartAxisWithData,
  layerSeries: readonly ChartSeries[],
): ChartAxisWithData {
  const hasGroupedBarSeries = layerSeries.some(
    (s) =>
      s.type === "bar" &&
      s.layout === "grouped" &&
      s.colorDataFrameColumn != null,
  );

  if (hasGroupedBarSeries) {
    if (xAxis.type === "number") {
      return {
        ...xAxis,
        type: "string",
      };
    } else if (xAxis.type === "datetime") {
      return {
        ...xAxis,
        type: "string",
        timeUnit: xAxis.timeUnit ?? "yearmonth",
      };
    }
  }
  return xAxis;
}

export function getFirstSeriesInGroup(
  series: ChartSeries[],
  seriesGroups: ChartSeriesGroup[],
  seriesGroupIndex: number,
): ChartSeries | undefined {
  const firstSeriesId = seriesGroups[seriesGroupIndex]?.[0];
  if (firstSeriesId != null) {
    return series.find((s) => s.id === firstSeriesId);
  }
}

export function resolveSeriesAxisWithinGroups(
  multiSeries: ChartSeries[],
  seriesGroups: ChartSeriesGroup[],
  // to be used when moving series between groups
  newSeriesGroups?: ChartSeriesGroup[],
): ChartSeries[] {
  const seriesById = keyBy(multiSeries, "id");
  // apply the format value from exist series in group
  const axisForSeriesGroups: readonly (ChartAxis | undefined)[] =
    seriesGroups.map((_, index) => {
      const firstSeriesInGroup = getFirstSeriesInGroup(
        multiSeries,
        seriesGroups,
        index,
      );
      return firstSeriesInGroup?.axis;
    });

  return (newSeriesGroups ?? seriesGroups)
    .flatMap((seriesIds, seriesGroupIndex) => {
      return seriesIds.map((seriesId) => {
        const series = seriesById[seriesId];
        const axis = axisForSeriesGroups[seriesGroupIndex];
        if (series != null && axis != null) {
          const newAxis: ChartAxis = { ...series.axis, style: axis.style };
          if (newAxis.type === "number" && axis.type === "number") {
            newAxis.numberFormat = axis.numberFormat;
          } else if (newAxis.type === "datetime" && axis.type === "datetime") {
            newAxis.datetimeFormat = axis.datetimeFormat;
          }
          return {
            ...series,
            axis: newAxis,
          };
        }
        return series;
      });
    })
    .filter(notEmpty);
}

function ifSeriesHorizontal(
  series: ChartSeries,
  ifyes: string,
  ifno: string,
): string {
  return series.type === "bar" && series.orientation === "horizontal"
    ? ifyes
    : ifno;
}

export function foldKeysLabel(
  series: ChartSeries,
  useFieldLabel = false,
): string | undefined {
  if (series == null) {
    return undefined;
  }
  return `${ifSeriesHorizontal(series, "X", "Y")}-Axis ${useFieldLabel ? "Field" : "Column"}`;
}

export function getChartSeriesSelectorTypeForSeries(
  chartSeries: ChartSeries,
): ChartTypeSelectorOption {
  if (chartSeries.type === "line") {
    return "line";
  } else if (chartSeries.type === "scatter") {
    return "scatter";
  } else if (chartSeries.type === "area") {
    if (chartSeries.normalize) {
      return "area_stacked100";
    } else {
      return "area_stacked";
    }
  } else if (chartSeries.type === "bar") {
    const { layout, orientation } = chartSeries;
    if (orientation === "horizontal") {
      return layout === "grouped"
        ? "bar_grouped"
        : layout === "stacked"
          ? "bar_stacked"
          : "bar_stacked100";
    } else {
      return layout === "grouped"
        ? "column_grouped"
        : layout === "stacked"
          ? "column_stacked"
          : "column_stacked100";
    }
  } else if (chartSeries.type === "histogram") {
    return "histogram";
  } else if (chartSeries.type === "pie") {
    return "pie";
  } else {
    assertNever(chartSeries, chartSeries);
  }
}

export const chartTypeToSeriesTypeName: Record<
  ChartTypeSelectorOption,
  string
> = {
  column_grouped: "Grouped column",
  column_stacked: "Stacked column",
  column_stacked100: "100% stacked column",
  bar_grouped: "Grouped bar",
  bar_stacked: "Stacked bar",
  bar_stacked100: "100% stacked bar",
  area_stacked: "Stacked Area",
  area_stacked100: "100% stacked area",
  histogram: "Histogram",
  line: "Line chart",
  scatter: "Scatter plot",
  pie: "Pie chart",
};

export function maybeClearChartFoldKeys(
  chart: LayeredChartSpec,
): LayeredChartSpec {
  const layer = chart.layers[0];
  if (
    layer?.series != null &&
    (layer.series.length > 1 ||
      (layer.series.length > 0 &&
        layer.series[0]!.dataFrameColumns?.length <= 1))
  ) {
    // no CHART_FOLD_KEYS allowed in X or facet columns
    // - for multi-series charts
    // - for single series charts with only one column
    //   - this handles histogram and pie implicitly
    chart.layers = [
      {
        ...layer,
        xAxis: {
          ...layer.xAxis,
          dataFrameColumn:
            layer.xAxis.dataFrameColumn === CHART_FOLD_KEYS
              ? undefined
              : layer.xAxis.dataFrameColumn,
        },
      },
    ];
    if (chart.facet != null) {
      const newFacet = { ...chart.facet };
      if (chart.facet?.facetHorizontal?.dataFrameColumn === CHART_FOLD_KEYS) {
        delete newFacet.facetHorizontal;
      }
      if (chart.facet?.facetVertical?.dataFrameColumn === CHART_FOLD_KEYS) {
        delete newFacet.facetVertical;
      }
      chart.facet = newFacet;
    }
  }
  return chart;
}
