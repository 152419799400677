import { StandardType } from "vega-lite/build/src/type.js";

import { FilledDynamicValueTableColumnType } from "../DynamicValue";
import { assertNever } from "../errors";

import { ChartAggregate, ChartDataType } from "./types";

export function dataFrameHeaderTypeToChartDataType(
  headerType: FilledDynamicValueTableColumnType,
): ChartDataType {
  switch (headerType) {
    case "STRING":
    case "UNKNOWN":
    case "BOOLEAN":
      return "string";
    case "DATE":
    case "DATETIME":
    case "DATETIMETZ":
    case "TIME":
      return "datetime";
    case "NUMBER":
      return "number";
    default:
      assertNever(headerType, headerType);
  }
}

export function getEncodingType(
  type: ChartDataType,
  aggregate?: ChartAggregate,
): StandardType {
  if (aggregate != null) {
    return "quantitative";
  } else if (type === "datetime") {
    return "temporal";
  } else if (type === "number") {
    return "quantitative";
  } else {
    return "ordinal";
  }
}
