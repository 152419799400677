import { assertNever } from "../../errors.js";
import { ExploreCustomTimeUnit } from "../../explore/exploreCustomTimeUnit.js";
import { ExploreTimeUnit } from "../../explore/types.js";
import {
  CalciteType,
  HqlAggregationFunction,
  calciteTypeToColumnType,
} from "../../hql/types.js";
import { dataFrameHeaderTypeToChartDataType } from "../chartDataTypeUtils.js";
import { ChartAggregate, ChartDataType, ChartTimeUnit } from "../types.js";

export function calciteTypeToChartDataType(
  calciteType: CalciteType,
): ChartDataType {
  return dataFrameHeaderTypeToChartDataType(
    calciteTypeToColumnType(calciteType),
  );
}

export function hqlAggToChartAgg(
  hqlAgg: HqlAggregationFunction,
): ChartAggregate {
  switch (hqlAgg) {
    case HqlAggregationFunction.Count:
      return "count";
    case HqlAggregationFunction.CountDistinct:
      return "distinct";
    case HqlAggregationFunction.Sum:
      return "sum";
    case HqlAggregationFunction.Avg:
      return "average";
    case HqlAggregationFunction.Min:
      return "min";
    case HqlAggregationFunction.Max:
      return "max";
    case HqlAggregationFunction.Median:
      return "median";
    case HqlAggregationFunction.StdDev:
      return "stdev";
    case HqlAggregationFunction.StdDevPop:
      return "stdevp";
    case HqlAggregationFunction.Variance:
      return "variance";
    case HqlAggregationFunction.VariancePop:
      return "variancep";
    default:
      assertNever(hqlAgg, hqlAgg);
  }
}

export function exploreTruncUnitToChartTimeUnit(
  truncUnit: ExploreTimeUnit,
): ChartTimeUnit {
  if (ExploreCustomTimeUnit.guard(truncUnit)) {
    return truncUnit;
  }
  switch (truncUnit) {
    case "year":
      return "year";
    case "quarter":
      return "yearquarter";
    case "month":
      return "yearmonth";
    case "week":
      return "yearweek";
    case "day":
      return "yearmonthdate";
    case "hour":
      return "yearmonthdatehours";
    case "minute":
      return "yearmonthdatehoursminutes";
    case "second":
      return "yearmonthdatehoursminutesseconds";
    case "dayofweek":
      return "day";
    default:
      assertNever(truncUnit, truncUnit);
  }
}
